import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'

// initial state
const state = {
  survey: [],
  surveyResponses: []
}

// getters
const getters = {
  survey: state => {
    return state.survey
  },
  surveyResponses: state => {
    return state.surveyResponses
  }
}

// actions
const actions = {
  getSurvey ({ commit }, id) {
    return HTTP.get('surveys/' + id)
      .then(response => {
        commit(types.SET_SURVEY, { survey: response.data })
        return response.data
      })
  },
  postSurveyResponse ({ commit }, payload) {
    return HTTP.post('survey_responses' + DOT_EXT, {
      user: payload.user,
      survey: payload.survey,
      submittedOn: new Date().toISOString().slice(0, 19).replace('T', ' ')
    })
      .then(response => {
        for (let i = 0; i < payload.responses.length; i++) {
          this.dispatch('postSurveyQuestionResponse', {
            surveyResponse: response.data['@id'],
            question: payload.responses[i].question,
            response: payload.responses[i].response,
            comment: payload.responses[i].comment
          })
        }
        return response.data
      })
  },
  postSurveyQuestionResponse ({ commit }, payload) {
    return HTTP.post('survey_question_responses' + DOT_EXT, payload)
      .then(response => {
        // commit(types.SET_SURVEY_QUESTION_RESPONSES, { surveyQuestionResponses: response.data })
      })
  },
  getSurveyResponses ({ commit }, params) {
    return HTTP.get('survey_responses', { params })
      .then(response => {
        commit(types.SET_SURVEY_RESPONSE, { surveyResponses: response.data["hydra:member"] })
        return response.data["hydra:member"]
      })
  }
}

// mutations
const mutations = {
  [types.SET_SURVEY] (state, { survey }) {
    state.survey = survey
  },
  [types.SET_SURVEY_RESPONSE] (state, { surveyResponses }) {
    state.surveyResponses = surveyResponses
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
