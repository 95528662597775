import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
// Views
// const Test = () => import('@/views/Test')
const Login = () => import('@/views/pages/Login')
const PasswordReset = () => import('@/views/pages/PasswordReset')
const Dashboard = () => import('@/views/Dashboard')

// ADMIN
// Views
const BusinessList = () => import('@/views/business/BusinessList')
const BusinessForm = () => import('@/views/business/BusinessForm')
const UserList = () => import('@/views/users/UserList')
const UserForm = () => import('@/views/users/UserForm')
const UserBecome = () => import('@/components/UserBecome')
const UserProfile = () => import('@/views/UserProfile')
const SupportCenter = () => import('@/views/support_center/SupportCenter')
const RevenueOverview = () => import('@/views/reports/RevenueOverview')

// participnats
const ParticipantList = () => import('@/views/participants/ParticipantList')
const ParticipantDetails = () => import('@/views/participants/ParticipantDetails')
const ParticipantForm = () => import('@/views/participants/ParticipantForm')
// sponsors
const SponsorList = () => import('@/views/sponsors/SponsorList')
// const SponsorDetails = () => import('@/views/sponsors/SponsorDetails')
// const SponsorForm = () => import('@/views/sponsors/SponsorForm')
const PublicNotice = () => import('@/views/PublicNotice')
// invoices
const InvoiceList = () => import('@/views/invoices/InvoiceList')
const InvoiceDetails = () => import('@/views/invoices/InvoiceDetails')
//const InvoiceForm = () => import('@/views/invoices/InvoiceForm')
// discount codes
const DiscountCodeList = () => import('@/views/discount_codes/DiscountCodeList')
const DiscountCodeForm = () => import('@/views/discount_codes/DiscountCodeForm')
// locations
const LocationList = () => import('@/views/locations/LocationList')
const LocationDetails = () => import('@/views/locations/LocationDetails')
const LocationForm = () => import('@/views/locations/LocationForm')

const AdminReports = () => import('@/views/reports/AdminReports')

// surveys
const SurveyResults = () => import('@/views/surveys/SurveyResults')

//website content
const ContentList = () => import('@/views/website/ContentList')
const SportsOffered = () => import('@/views/website/SportsOffered')

//local website content
const Pages = () => import('@/views/website-local/Pages')
const HappeningNow = () => import('@/views/website-local/HappeningNow')
const HappeningNowDetails = () => import('@/views/website-local/HappeningNowDetails')
const HappeningNowForm = () => import('@/views/website-local/HappeningNowForm')
const BusinessPublicForm = () => import('@/views/website-local/BusinessPublicForm')
const Seo = () => import('@/views/website-local/Seo')

// events
const EventForm = () => import('@/views/events/EventForm')
const EventDetails = () => import('@/views/events/EventDetails')
const EventGroup = () => import('@/components/EventGroup')
const EventList = () => import('@/views/events/EventList')
const EventSchedule = () => import('@/views/events/EventSchedule')
const EventScheduleGenerator = () => import('@/views/events/EventScheduleGenerator')
const EventStandings = () => import('@/views/events/EventStandings')
const EventRegistrationList = () => import('@/views/events/EventRegistrationList')
const EventRegistrationDetails = () => import('@/views/events/EventRegistrationDetails')
const EventRegistrationForm = () => import('@/views/events/EventRegistrationForm')


// DIRECTOR
// Views
const RevenueForm = () => import('@/views/reports/RevenueForm')
const RevenueDetails = () => import('@/views/reports/RevenueDetails')
const RevenueList = () => import('@/views/reports/RevenueList')
const MarketingForm = () => import('@/views/reports/MarketingForm')
const MarketingDetails = () => import('@/views/reports/MarketingDetails')
const MarketingList = () => import('@/views/reports/MarketingList')
const MarketingOverview = () => import('@/views/reports/MarketingOverview')
const ApplicantList = () => import('@/views/ApplicantList')
const RosterCreate = () => import('@/components/RosterCreate')
const CustomMessages =() => import('@/components/CustomMessages')

// Views - Pages
// const Page404 = () => import('@/views/pages/Page404')
// const Page500 = () => import('@/views/pages/Page500')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

// Views - UI Kits
// const Invoice = () => import('@/views/apps/invoicing/Invoice')
const Compose = () => import('@/views/apps/email/Compose')
// const Inbox = () => import('@/views/apps/email/Inbox')
// const Message = () => import('@/views/apps/email/Message')

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) next()
  else next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated && store.dispatch('getCurrentUser', store.getters.currentUser.username)) {
    // username update (hack for legacy to current)
    if (to.name !== 'UserProfile' &&
      !store.getters.currentUser.originalUser &&
      store.getters.currentUser.fullName === store.getters.currentUser.username) 
      next({
        name: 'UserProfile',
        query: {
          action: 'profile-update'
        }
      })
    else next()
  }
  else next('/auth/login')
}

Vue.use(Router)

export default new Router({
  mode: 'hash', // Demo is living in GitHub.io, so required!
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'home',
      meta: { label: 'NZS' },
      component: DefaultContainer,
      beforeEnter: ifAuthenticated,
      children: [
        // {
        //   path: '/test',
        //   name: 'test',
        //   component: Test
        // },
        {
          path: '/dashboard',
          name: 'Home',
          component: Dashboard
        },
        {
          path: '/messages',
          name: 'Messages',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/messages/compose',
              meta: { label: 'Compose' },
              name: 'Compose',
              component: Compose
            }
          ]
        },
        {
          path: '/user',
          name: 'users',
          redirect: '/user/list-all',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/user/form/:uid?',
              meta: { label: 'Form' },
              name: 'userForm',
              component: UserForm
            },
            {
              path: '/user/list-all',
              meta: { label: 'List' },
              name: 'userListAll',
              component: UserList,
              props: { userRole: null }
            },
            {
              path: '/user/list-admins',
              meta: { label: 'List' },
              name: 'userListAdmins',
              component: UserList,
              props: { userRole: 'admin' }
            },
            {
              path: '/user/list-directors',
              meta: { label: 'List' },
              name: 'userListDirectors',
              component: UserList,
              props: { userRole: 'director' }
            },
            {
              path: '/user/list-coaches',
              meta: { label: 'List' },
              name: 'userListCoaches',
              component: UserList,
              props: { userRole: 'coach' }
            },
            {
              path: '/user/list-coaches-jr',
              meta: { label: 'List' },
              name: 'userListCoachesJr',
              component: UserList,
              props: { userRole: 'coachJr' }
            },
            {
              path: '/user/list-members',
              meta: { label: 'List' },
              name: 'userListMembers',
              component: UserList,
              props: { userRole: 'member' }
            },
            {
              path: '/user/become',
              meta: { label: 'Body Snatcher!' },
              name: 'userBecome',
              component: UserBecome
            }
          ]
        },
        {
          path: '/participants',
          name: 'participants',
          redirect: '/participants/list',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/participants/form/:uid?',
              meta: { label: 'Form' },
              name: 'participantForm',
              component: ParticipantForm
            },
            {
              path: '/participants/list',
              meta: { label: 'List' },
              name: 'participantList',
              component: ParticipantList
            },
            {
              path: '/participants/details/:pid?',
              meta: { label: 'Details' },
              name: 'participantDetails',
              component: ParticipantDetails
            }
          ]
        },
        {
          path: '/locations',
          name: 'locations',
          redirect: '/locations/list',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/locations/form',
              meta: { label: 'Form' },
              name: 'locationForm',
              component: LocationForm
            },
            {
              path: '/locations/list',
              meta: { label: 'List' },
              name: 'locationList',
              component: LocationList
            },
            {
              path: '/locations/details',
              meta: { label: 'Details' },
              name: 'locationDetails',
              component: LocationDetails
            }
          ]
        },
        {
          path: '/invoices',
          name: 'invoices',
          meta: { label: 'Invoices' },
          redirect: '/invoices/list',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
//            {
//              path: '/discount-codes/form',
//              meta: { label: 'Form' },
//              name: 'sponsorForm',
//              component: DiscountCodeForm
//            },
            {
              path: '/invoices/list',
              meta: { label: 'List' },
              name: 'invoiceList',
              component: InvoiceList
            },
            {
              path: '/invoices/details/:iid',
              meta: { label: 'Details' },
              name: 'invoiceDetails',
              component: InvoiceDetails
            }
          ]
        },
        {
          path: '/franchise',
          name: 'franchise',
          redirect: '/business/list',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/business/form/:bid?',
              meta: { label: 'Form' },
              name: 'businessForm',
              component: BusinessForm
            },
            {
              path: '/business/list',
              meta: { label: 'List' },
              name: 'BusinessList',
              component: BusinessList
            }
          ]
        },
        {
          path: '/reports',
          meta: { label: 'Reports' },
          name: 'reports',
          redirect: '/reports/revenue/list',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            /* {
              path: '/reports/overview',
              meta: { label: 'Overview' },
              name: 'reportsOverview',
              component: AdminReports
            }, */
            {
              path: '/reports/revenue/overview',
              meta: { label: 'Revenue Overview' },
              name: 'revenueOverview',
              component: RevenueOverview
            },
            {
              path: '/reports/revenue/form/:rptId?',
              meta: { label: 'Revenue Form' },
              name: 'reportsRevenueForm',
              component: RevenueForm
            },
            {
              path: '/reports/revenue/details/:rptId',
              meta: { label: 'Revenue Details' },
              name: 'reportsRevenueDetails',
              component: RevenueDetails
            },
            {
              path: '/reports/revenue/list',
              meta: { label: 'Revenue List' },
              name: 'reportsRevenueList',
              component: RevenueList
            },
            {
              path: '/reports/marketing/form/:rptId?',
              meta: { label: 'Marketing Form' },
              name: 'reportsMarketingForm',
              component: MarketingForm
            },
            {
              path: '/reports/marketing/details/:rptId',
              meta: { label: 'Marketing Details' },
              name: 'reportsMarketingDetails',
              component: MarketingDetails
            },
            {
              path: '/reports/marketing/list',
              meta: { label: 'Marketing List' },
              name: 'reportsMarketingList',
              component: MarketingList
            },
            {
              path: '/reports/marketing/overview',
              meta: { label: 'Marketing Overview' },
              name: 'reportsMarketingOverview',
              component: MarketingOverview
            }
          ]
        },
        {
          path: '/surveys',
          name: 'Surveys',
          component: {
            render (c) { return c('router-view') }
          },
          props: true,
          children: [
            {
              path: '/surveys/results/:surveyId/:year?/:month?',
              meta: { label: 'Submissions' },
              name: 'Submissions',
              component: SurveyResults,
              props: true,
            }
          ]
        },
        {
          path: 'support',
          name: 'Support Center',
          component: SupportCenter
        },
        {
          path: 'applicants',
          name: 'Applicants',
          component: ApplicantList
        },
        {
          path: 'website',
          name: 'website',
          meta: { label: 'Website' },
          redirect: '/website/content',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/website/content',
              meta: { label: 'Content' },
              name: 'ContentList',
              component: ContentList
            },
            {
              path: '/website/sports-offered',
              meta: { label: 'Sports' },
              name: 'SportsOffered',
              component: SportsOffered
            }
          ]
        },
        {
          path: '/event-group/:egid',
          meta: { label: 'Event Group' },
          name: 'eventGroup',
          component: EventGroup
        },
        {
          path: 'events',
          name: 'events',
          redirect: '/events/all',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/events/new',
              meta: { label: 'New' },
              name: 'eventNew',
              component: EventForm
            },
            {
              path: '/events/edit/:eid',
              meta: { label: 'Edit' },
              name: 'eventEdit',
              component: EventForm
            },
            {
              path: '/events/diplicate/:eid',
              meta: { label: 'Duplicate' },
              name: 'eventDuplicate',
              component: EventForm
            },
            {
              path: '/events/all',
              meta: { label: 'List' },
              name: 'eventList',
              component: EventList
            },
            {
              path: '/events/details/:eid',
              meta: { label: 'Details' },
              name: 'eventDetails',
              component: EventDetails
            },
            {
              path: '/events/:eid/schedule/generator',
              meta: { label: 'Schedule' },
              name: 'eventScheduleGenerator',
              component: EventScheduleGenerator
            },
            {
              path: '/events/schedule/:eid',
              meta: { label: 'Schedule' },
              name: 'eventSchedule',
              component: EventSchedule
            },
            {
              path: '/events/standings/:eid',
              meta: { label: 'Standings' },
              name: 'eventStandings',
              component: EventStandings
            },
            {
              path: '/events/registrations/details/:rid?',
              meta: { label: 'Registration Details' },
              name: 'eventRegistrationDetails',
              component: EventRegistrationDetails
            },
            {
              path: '/events/registrations/form/:rid?',
              meta: { label: 'Registration Form' },
              name: 'eventRegistrationForm',
              component: EventRegistrationForm
            },
            {
              path: '/events/registrations/:eid?/:uid?',
              meta: { label: 'Registrations' },
              name: 'eventRegistrationList',
              component: EventRegistrationList
            },
            {
              path: '/events/roster/create/:eid?',
              meta: { label: 'Roster Create (Beta)' },
              name: 'rosterCreate',
              component: RosterCreate
            }
          ]
        },
        {
          path: 'website-local',
          name: 'localSite',
          meta: { label: 'Local Site' },
          redirect: '/website-local/pages',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/website-local/pages/:action?/:slug?',
              meta: { label: 'Page' },
              name: 'Pages',
              component: Pages
            },
            {
              path: '/website-local/happening-now',
              meta: { label: 'Happening Now' },
              name: 'happeningNow',
              component: HappeningNow
            },
            {
              path: '/website-local/happening-now/form/:id?',
              meta: { label: 'New Happening Now Post' },
              name: 'happeningNowForm',
              component: HappeningNowForm
            },
            {
              path: '/website-local/happening-now/:slug',
              meta: { label: 'Post Details' },
              name: 'happeningNowDetails',
              component: HappeningNowDetails
            },
            {
              path: '/website-local/custom-messages',
              meta: { label: 'Custom Messages' },
              name: 'customMessages',
              component: CustomMessages
            },
            {
              path: '/website-local/public-settings',
              meta: { label: 'Settings' },
              name: 'businessPublicData',
              component: BusinessPublicForm
            },
            {
              path: '/website-local/locations',
              meta: { label: 'Locations' },
              name: 'websiteLocationList',
              component: LocationList
            },
            {
              path: '/website-local/sponsors',
              meta: { label: 'Sponsors' },
              name: 'sponsors',
              component: SponsorList
            },
            {
              path: '/website-local/notice/:pnid?',
              meta: { label: 'Sponsors' },
              name: 'notice',
              component: PublicNotice
            },
            {
              path: '/website-local/discount-codes',
              meta: { label: 'Discount Codes' },
              name: 'discountCodes',
              redirect: '/website-local/discount-codes/list',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '/website-local/discount-codes/form/:did?',
                  meta: { label: 'Form' },
                  name: 'discountCodeForm',
                  component: DiscountCodeForm
                },
                {
                  path: '/website-local/discount-codes/list',
                  meta: { label: 'List' },
                  name: 'discountCodeList',
                  component: DiscountCodeList
                }
              ]
            },
            {
              path: '/website-local/seo',
              meta: { label: 'SEO' },
              name: 'seo',
              component: Seo
            }
          ]
        },
        {
          path: '/me',
          meta: { label: 'Me' },
          name: 'UserProfile',
          component: UserProfile
        }
      ]
    },
    {
      path: '/auth',
      redirect: '/404',
      name: 'Auth',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'password-reset',
          name: 'passwordReset',
          component: PasswordReset,
          beforeEnter: ifNotAuthenticated
        }
      ]
    }
  ]
})
